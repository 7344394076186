<template>
  <Transition name="overlay" appear>
    <UiModal
      v-if="isOverlayVisible && matchingOverlay"
      class="modal-container"
      width="auto"
      @close="closeModal"
    >
      <template #body>
        <UiSmartLink
          :to="matchingOverlay.urlPath"
          :target="matchingOverlay.isOpenInNewTab ? '_blank' : ''"
          :rel="matchingOverlay.isOpenInNewTab ? 'noopener noreferrer' : ''"
        >
          <UiSmartImage
            :skeleton="true"
            :lazy-loading="false"
            v-bind="matchingOverlay.image"
            :fetch-priority="'high'"
            aspect-ratio="16/10"
            aspect-ratio-mobile="10/16"
          />
        </UiSmartLink>
      </template>
    </UiModal>
  </Transition>
</template>

<script lang="ts" setup>
import UiModal from '@sephora-asia/ui-modal'
import UiSmartLink from '@sephora-asia/ui-smart-link'
import UiSmartImage from '@sephora-asia/ui-smart-image'
import type { OverlayDTO } from '~/types/api/bff/web/contentful/component.types.ts'
import { useMatchingOverlay } from '~/composables/utils/overlays/use-matching-overlay'
import { useSyncSession } from '~/composables/utils/overlays/use-sync-session'
import type { SmartImageAssetDTO } from '~/types/api/bff/web/contentful/asset.types'

const props = defineProps<{ overlays: OverlayDTO[] }>()
const isOverlayVisible = ref(false)

const sessionKey = 'OVERLAY_SESSION'
const broadcastChannelKey = 'overlay_channel'

const { isMobile } = useDevice()
const { matchingOverlay } = useMatchingOverlay(props.overlays)
const { onInitTab, onOpenTab, isKeyIncludedInSessionData } = useSyncSession(
  sessionKey,
  broadcastChannelKey
)

onMounted(() => {
  onOpenTab()
  // Delay to synchronize data across tabs.
  // If the page has an overlay, it checks whether this overlay has been shown or not.
  !matchingOverlay.value
    ? onOpenTab()
    : setTimeout(() => {
        handleOverlayInitialization()
      }, 500)
})

watch(matchingOverlay, () => {
  matchingOverlay.value && handleOverlayInitialization()
})

function isImageAvailable(image?: SmartImageAssetDTO) {
  return isMobile ? !!image?.srcMobile : !!image?.src
}

function handleOverlayInitialization() {
  isOverlayVisible.value =
    !isKeyIncludedInSessionData(matchingOverlay.value?.id) &&
    isImageAvailable(matchingOverlay.value?.image)

  onInitTab(matchingOverlay.value)
}

function closeModal() {
  isOverlayVisible.value = false
}
</script>

<style lang="scss" scoped>
.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.5s ease;
}

.modal-container {
  --smart-image-width: 800px;

  :deep(.smart-image) {
    width: min(80vw, var(--smart-image-width));

    @include mobile {
      --smart-image-width: 600px;
    }
  }

  :deep(.content) {
    background-color: unset !important;
    animation: bounce 0.25s ease-in;

    .header {
      position: relative;
    }

    .body {
      margin: 0 !important;
    }

    .icon-container {
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: var(--size-z-index-modal);
      width: 24px;
      height: 24px;
      background-color: var(--color-base-grey-10);

      .icon-close {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.05);
  }
  85% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
